.ccl_background {
	width: 100%;
	height: 100%;
	background: url("../images/ccl_wolken.svg");
	background-repeat: no-repeat;
	background-position: center 20%;
	background-size: 950px;
	position: absolute;
}

.ccl_container {
	h1 {
		margin-top: 0px;
		font-size: 40px;
		font-weight: 600;
		margin-left: -3px;
	}

	h2 {
		font-size: 20px;
		line-height: 20px;
		font-weight: 600;
		margin: 10px 0px;
	}

	p {
		color: white;
		line-height: 21px;
		font-size: 14px;
	}

	* {
		text-align: left;
	}

	.colofon_links, .contact_links, .links_links {
		float: left;
		display: inline-block;
		margin-right: 100px;
	}

	.links_links {
		margin-right: 40px;
	}

	.colofon_rechts, .contact_rechts, .links_rechts {
		display: inline-block;
	}

	.colofon_links, .colofon_rechts {
		width: 810px;
		margin-right: 0px;
	}

	.contact_links {
		margin-right: 15px;
	}

	.contact_column {
		background: #96cfea;
		width: 350px;
		padding: 15px 40px 15px 40px;
		height: 130px;
		margin-bottom: 15px;

		a {
			padding: 5px 10px 5px 10px;
			background: $blue;

			&:hover {
				text-decoration: underline;
			}
		}

		p {
			a {
				padding: 0px;
				background: none;
				text-decoration: underline;
			}
		}
	}

	.links_background {
		background: #8fcae7;
		padding: 30px 40px 30px 40px;

		a {
			text-decoration: underline;
		}
	}

	.colofon_background {
		background: #8fcae7;
		padding: 15px 40px 15px 40px;
		min-height: 270px;

		a {
			padding: 0px;
			background: none;
			text-decoration: underline;
		}
	}
}

.contact_container {
	width: 875px;

	h1 {
		margin-left: 37px;
	}
}

.links_container {
	h1 {
		margin-left: 37px;
	}
}

.colofon_container {
	h1 {
		margin-left: 37px;
		margin-top: -45px;
	}
}