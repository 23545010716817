.inner_container {
	z-index: 10;

    .logo_homepage {
		background: url("../images/logo_homepage.svg");
		background-size: cover;
		width: 900px;
		height: 420px;
		margin: 10px auto;

		@media screen and (max-width: 1024px) {
			width: 760px;
			height: 353px;
		}
	}

	.home_menu {
		list-style: none;
		padding-left: 0px;
		margin: 0px;
		text-align: center;

		li {
			display: inline-block;
			text-align: center;
			width: 160px;
	    	vertical-align: top;
	    	margin: 0 5px;
	    	cursor: pointer;

	    	img {
		    	outline: none;
		    	border: 0px;
	    	}

	    	h1 {
	    		color: $blue;
	    		transition: $transition;
				-webkit-transition: $transition;
			}

			&:hover h1 {
				opacity: .5;
			}
		}
	}
}