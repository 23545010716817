/* Sass freq variables */
/* Colors */

$red : #e4322f;
$light_red : #ea5552;
$blue : #5583ac;
$light_blue : #a1deea;
$transition : all .25s ease-in-out;

/* Fonts */

$arial : Arial, 'Helvetica Neue', Helvetica, sans-serif;