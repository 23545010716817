@import "gezond";

@import "duurzaam";

@import "uitleg";

@import "spreekbeurt";

.lightbox_frame {
	width: 900px;
	height: 580px;
	position: relative;
	overflow: hidden;

	background: #dae3ea;

	.lightbox_header {
		height: 100px;
		width: 100%;

		h1 {
			font-size: 42px;
			font-weight: 600;
			line-height: 100px;
			margin: 0px;
		}
	}

	.lightbox_container {
	   	height: 390px;
	   	margin: 40px;

		.lightbox_left_content {
			width: 50%;
			float: left;
			display: inline-block;
			color: $blue;
			line-height: 23px;
			max-height: 390px;

			-webkit-overflow-scrolling: touch;
    		overflow: auto;

			p {
				margin: 0px;
			}

			a {
				color: $blue;
				text-decoration: underline;
			}

			h2 {
				color: white;
	    		font-size: 28px;
	    		text-align: left;
			}
		}

		.lightbox_right_content {
			width: 45%;
			float: left;
			display: inline-block;
			margin-left: 40px;

			img {
				max-height: 390px;
				max-width: 100%;
				margin: auto;
				display: block;
				/*margin-top: 20px;*/
			}

			/*p {
				margin: 0px;
				color: $blue;
				text-align: center;
				font-size: 14px;
			}*/

			iframe {
				width: 100%;
				height: 390px;
			}
		}

		.lightbox_left_content_full, .lightbox_right_content_full {
			width: calc(100%);
			max-width: inherit;
			margin: 0px;
			float: none;

			img {
				max-width: 100%;
				display: block;
				margin-right: auto;
				float: none;
			}
		}
		}

		.lightbox_footer {
			position: absolute;
			bottom: 0px;
			height: 15px;
			width: 100%;
		}
}

/* Styling the lightbox frame and closing button */
#colorbox {
	overflow: hidden;
	z-index: 150;
}

.cboxIframe {
	width: 100%;
	height: 100%;
	position: absolute;
	overflow: hidden;
	z-index: 150;
	background: url("../images/preloader.gif") center center no-repeat #dae3ea;
}

#cboxOverlay {
	opacity: 0.5 !important;
    background: black;
    width: 100%;
    height: 100%;
    top: 0px;
    position: fixed;
    z-index: 130;
	overflow: hidden;
}

#cboxClose {
	z-index: 160;
    position: absolute;
    top: 27.5px !important;
    right: 40px !important;
    background: url("../images/lightbox_cross.svg");
    border: none;
    color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    height: 45px;
    width: 45px;
    padding: 0px;
    opacity: 1;
}
