body.is_touch {
	.uitleg_plaat {
		background: url("../images/hoe_maken_wij_jouw_water/uitleg_plaat.png") !important;
		background-size: contain !important;
		background-repeat: no-repeat !important;
	}

	.plaat_img {
		display: none;
	}
}

.uitleg_background {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0px;

	background: #a0dde9; /* Old browsers */
	background: -moz-linear-gradient(top,  #a0dde9 24%, #d0eef4 calc(50% + 35.5px), #97c32a calc(50% + 35.5px)); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  #a0dde9 24%, #d0eef4 calc(50% + 35.5px), #97c32a calc(50% + 35.5px)); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  #a0dde9 24%, #d0eef4 calc(50% + 35.5px), #97c32a calc(50% + 35.5px)); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a0dde9', endColorstr='#97c32a', GradientType=0 ); /* IE6-9 */

	.inner_container {
		width: 100%;

		.uitleg_plaat {
			position: relative;
			height: 590px;
			width: 6557px;
			background: url("../images/hoe_maken_wij_jouw_water/uitleg_plaat.svg");
			background-size: contain;
			background-repeat: no-repeat;

			.link_plaat {
				width: 31px;
				height: 31px;
				position: absolute;
				opacity: 0;
				border-radius: 50%;
				cursor: pointer;

				transition: $transition;
			    -webkit-transition: $transition;

				background: url("../images/hoe_maken_wij_jouw_water/i_icon.svg");
				background-size: contain;
				background-repeat: no-repeat;

				&:hover {
					opacity: 1;
				}
			}

			.link_plaat_1 { left: 700px; top: 148px; }
			.link_plaat_2 { left: 844px; top: 148.5px; }
			.link_plaat_3 { left: 1105.2px; top: 207px; }
			.link_plaat_4 { left: 1287.5px; top: 161.5px; }
			.link_plaat_5 { left: 1714.3px; top: 169.5px; }
			.link_plaat_6 { left: 1838.1px; top: 173.4px; }
			.link_plaat_7 { left: 2248.5px; top: 164.5px; }
			.link_plaat_8 { left: 2401.5px; top: 250.5px; }
			.link_plaat_9 { left: 2824.5px; top: 155.5px; }
			.link_plaat_10 { left: 2934px; top: 156.5px; }
			.link_plaat_11 { left: 3104.5px; top: 105.3px; }
			.link_plaat_12 { left: 3284.3px; top: 248.5px; }
			.link_plaat_13 { left: 4166.3px; top: 175.7px; }
			.link_plaat_14 { left: 4330.3px; top: 100.5px; }
			.link_plaat_15 { left: 4767.5px; top: 183px; }
			.link_plaat_16 { left: 4962px; top: 183px; }
			.link_plaat_17 { left: 5258.6px; top: 100px; }
			.link_plaat_18 { left: 5246.7px; top: 288px; }

			.plaat_img {
				position: absolute;
				left: 4559.5px;
    			top: 271px;
			}
		}
	}
}

.uitleg_arrows {
	position: fixed;
	width: 100%;
	top: 50px;

	.outer_arrow_left {
		height: 105px;
    	left: 0px;
    	width: 100px;
    	top: -15px;
    	position: absolute;

    	&:hover .left:after {
    		opacity: 1;
    	}
	}

	.outer_arrow_right {
		height: 105px;
    	right: 0px;
    	width: 100px;
    	top: -15px;
    	position: absolute;

    	&:hover .right:after {
    		opacity: 1;
    	}
	}

	.arrow {
		width: 70px;
		height: 70px;
		cursor: pointer;
		background: $red;
		display: none;
		margin-top: 15px;

		-moz-box-sizing: border-box; 
        -webkit-box-sizing: border-box; 
     	box-sizing: border-box; 
	}

	.left {
		float: left;
		border-top-right-radius: 35px; 
		border-bottom-right-radius: 35px;

		&:after {
			content: "";
			width: 70px;
			height: 70px;
			position: absolute;
			top: 15px;
			display: block;
			background: url("../images/hoe_maken_wij_jouw_water/arrow_left.svg");
			background-size: 50px 50px;
			background-repeat: no-repeat;
			background-position: center center;
			opacity: .5;

			transition: $transition;
			-webkit-transition: $transition;
		}

		&:hover:after {
			opacity: 1;
		}
	}

	.right {
		float: right;
		border-top-left-radius: 35px; 
		border-bottom-left-radius: 35px;

		&:after {
			content: "";
			width: 70px;
			height: 70px;
			position: absolute;
			top: 15px;
			display: block;
			background: url("../images/hoe_maken_wij_jouw_water/arrow_right.svg");
			background-size: 50px 50px;
			background-repeat: no-repeat;
			background-position: center center;
			opacity: .5;

			transition: $transition;
			-webkit-transition: $transition;
		}

		&:hover:after {
			opacity: 1;
		}
	}
}

.uitleg_nav {
	width: 153px;
    position: fixed;
    bottom: 11px;
    text-align: center;
    z-index: 11;
    margin-left: 50%;
    left: -76.5px;
    border-radius: 15px;
    height: 33px;

	.uitleg_nav_list {
		list-style: none;
		padding: 0px;
		margin: 0px;
		line-height: 49px;
    	background: #e4322f;
    	border-radius: 17px;

		li {
			width: 22px;
			height: 22px;
			border-radius: 50%;
			border: 1px solid white;
			display: inline-block;
			cursor: pointer;
			transition: $transition;
			-webkit-transition: $transition;

			&:hover {
				background: rgba(255,255,255,.5);
			}

			p {
				color: white;
				line-height: 11px;
				position: relative;
				background: none !important;
				top: -7px;
				font-size: 13px;
			}
		}

		:nth-child(1) {
			background: white;

			p {
				color: #97c32a;
			}
		}
	}
}