.is_touch {
	.gezond_text_wolken {
		.wolk {
			opacity: 0 !important;
		}
	}
}

.gezond_background {
	width: 100%;
	height: 100%;
	background: url("../images/gezond/gezond_wolken.svg") #99c62f;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 900px;
	position: absolute;
}

.inner_container {
	.gezond_text {
		width: 360px;
    	height: 146.3px;
		background: url("../images/gezond/gezond_text.svg");
		background-size: cover;
	}

	.gezond_vijf {
		background-size: cover;
		position: absolute;

		.bordje {
			width: 175px;
			position: absolute;

			h1 {
				color: $blue;
				font-weight: 600;
				font-size: 18px;
				transition: $transition;
			    -webkit-transition: $transition;
			}

			&:hover h1 {
				background: $red;
				-webkit-box-shadow: inset 0px 4px 0px 0px rgba(239,101,101,1);
                -moz-box-shadow: inset 0px 4px 0px 0px rgba(239,101,101,1);
				box-shadow: inset 0px 4px 0px 0px rgba(239,101,101,1);
				color: white;
			}

			a {
				display: block;
    			height: 100%;
    			width: 100%;
    			top: 0px;
			    position: absolute;
			}
		}

		.bordje_lijf {
			height: 215px;
			background: url("../images/gezond/gezond_lijf.svg");
    		background-size: contain;
    		background-repeat: no-repeat;
			top: -370px;
			left: 87px;

			h1 {
				line-height: 33px;
    			width: 158px;
    			height: 33px;
    			margin: 158px auto;
			}
		}

		.bordje_fabel {
			height: 173px;
			background: url("../images/gezond/gezond_fabel.svg");
    		background-size: contain;
    		background-repeat: no-repeat;
			top: -200px;
			left: 400px;

			h1 {
				line-height: 36px;
    			width: 175px;
    			height: 36px;
    			margin: 111px auto;
			}
		}

		.bordje_drinkwater {
			height: 182.2px;
    		width: 178px;
			background: url("../images/gezond/gezond_drinkwater.svg");
    		background-size: contain;
    		background-repeat: no-repeat;
			top: 20px;
			left: 260px;

			h1 {
				line-height: 36px;
    			width: 175px;
    			height: 36px;
    			margin: 121px 3px;
			}
		}

		.bordje_ziek_tv {
			height: 178.97px;
			background: url("../images/gezond/gezond_ziek_tv.svg");
    		background-size: contain;
    		background-repeat: no-repeat;
			top: 20px;
			left: -50px;

			h1 {
				line-height: 36px;
    			width: 175px;
    			height: 36px;
    			margin: 117px auto;
			}
		}

		.bordje_smaakt {
			height: 174.79px;
			background: url("../images/gezond/gezond_smaakt.svg");
    		background-size: contain;
    		background-repeat: no-repeat;
			top: -200px;
			left: -200px;

			h1 {
				line-height: 19px;
    			width: 142px;
    			height: 35px;
    			margin: 96px 0px;
    			padding: 10px 10px;
			}
		}
	}

	.gezond_text_wolken {
		.wolk {
			width: 220px;
			position: absolute;
			opacity: 0;
		}

		.lijf {
			background: url("../images/gezond/gezond_wolk_lijf.svg");
			background-size: cover;
    		height: 100px;
    		left: 275px;
    		top: -180px;
		}

		.feit {
			background: url("../images/gezond/gezond_wolk_feit.svg");
			background-size: cover;
    		height: 130px;
    		width: 185px;
    		left: 485px;
    		top: -190px;
		}

		.drinkwater {
			background: url("../images/gezond/gezond_wolk_waterpomp.svg");
			background-size: cover;
    		height: 110px;
    		left: 425px;
    		top: 190px;
		}

		.ziek_tv {
			background: url("../images/gezond/gezond_wolk_ziek.svg");
			background-size: cover;
    		height: 63px;
    		left: -285px;
    		top: 220px;
		}

		.smaakt {
			background: url("../images/gezond/gezond_wolk_smaakt.svg");
			background-size: cover;
    		height: 111px;
    		width: 185px;
    		left: -285px;
    		top: -180px;
		}
	}
}