html, body {
	height: 100%;
	width: 100%;
	margin: 0px;
	padding: 0px;
	overflow: hidden;
	position: fixed;
}

body {
	-webkit-font-smoothing: antialiased;
	font-family: $arial;
	background: -moz-linear-gradient(top, #a3dfeb 0%, #d8f1f6 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #a3dfeb 0%,#d8f1f6 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #a3dfeb 0%,#d8f1f6 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.container {
	width: 100%;
	height: 100%;
	background: -moz-linear-gradient(top, #a3dfeb 0%, #d8f1f6 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #a3dfeb 0%,#d8f1f6 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #a3dfeb 0%,#d8f1f6 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

header {
	background: $red;
	top: 0px;
	position: fixed;
	width: 100%;
	height: 30px;
	line-height: 30px;
	font-weight: 200;
	font-size: 13px;
	z-index: 100;

	.nav_left {
		float: left;

		a {
			border-right: 1px solid rgba(255,255,255,0.4);
		}

		.active {
			background: $blue;
		}
	}

	.logo_header {
		margin: auto;
		background: url("../images/logo_header.svg");
		width: 90px;
    	height: 71px;
    	margin-top: 10px;

    	a {
    		width: 100%;
    		height: 100%;
    		display: block;
    	}
	}

	.nav_right {
		float: right;
		top: 0px;
    	position: absolute;
    	right: 0px;

		a {
			border-right: none;
			border-left: 1px solid rgba(255,255,255,0.4);
		}
	}

	ul {
		color: white;
		list-style: none;
		padding-left: 0px;
		margin: 0px;

		a {
			display: table-cell;
			padding: 0px 10px 0px 10px;
			transition: $transition;
			-webkit-transition: $transition;
			color: white;

			&:hover {
				background: $light_red;
			}
		}
	}
}

footer {
	background: $red;
	bottom: 0px;
	position: fixed;
	width: 100%;
	height: 30px;
	line-height: 13px;
	font-weight: 200;
	font-size: 13px;
	z-index: 10;

	nav {
		margin-top: 8.5px;
	}

	ul {
		color: white;
		list-style: none;
		padding-left: 0px;
		margin: 0px;

		a {
			display: table-cell;
			padding: 0px 20px 0px 20px;
			transition: $transition;
			color: white;

			&:nth-child(2) {
				border-left: 1px solid white;
				border-right: 1px solid white;
			}

			&:hover {
				text-decoration: underline;
			}
		}

		.active {
			text-decoration: underline;
		}
	}
}

.inner_container {
	top: 50%;
  	left: 50%;
  	transform: translate3d(-50%,-50%, 0);
  	-webkit-transform: translate3d(-50%,-50%, 0);
  	position: absolute;
}

.footer_trees {
	pointer-events: none;
	background: url("../images/footer_trees.svg");
	background-size: cover;
	width: 1000px;
    height: 145px;
    margin: auto;
    bottom: 15px;
    position: absolute;
    margin-left: 50%;
    left: -500px;
}