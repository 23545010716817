.tablet_portrait {
	display: none;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 110;
	background: url("../images/tablet_background.png") $blue;
	background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;

	.tablet_logo {
		width: 100%;
		text-align: center;
		z-index: 120;
		position: absolute;
		top: 10px;
	}
}

@media screen and (max-width: 899px) {
	body.is_touch * {
		display: none;
	}

	html, body, body.is_touch .tablet_portrait, body.is_touch .tablet_portrait * {
		display: block;
	}

	body.is_touch .tablet_logo img {
		display: inline;
	}
}

@media screen and (max-width: 767px) {
	*, body.is_touch .tablet_portrait, body.is_touch .tablet_portrait * {
		display: none;
	}

	html, body {
		display: block;
		width: 100%;
		height: 100%;
	}

	body {
		background: url("../images/mobile_error.svg");
		background-size: cover;
    	background-position: top center;
    	background-repeat: no-repeat;
    	position: absolute;
	}
}