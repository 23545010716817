.inner_container {
	.welkom_text {
		background: url("../images/hoe_maken_wij_jouw_water/text_welkom_water.svg");
		background-size: cover;
		width: 563px;
    	height: 60px;
    	margin: 0 auto 10px;
	}

	.welkom_personages {
		background: url("../images/hoe_maken_wij_jouw_water/welkom_voorstellen.svg");
		background-size: cover;
		width: 700px;
    	height: 172px;
    	margin: auto;
    	position: relative;
    	left: -9px;

    	.person {
    		height: 100%;
    		width: 121px;
    		border-radius: 50%;
    		display: inline-block;
    		cursor: pointer;
    	}

    	.click_onderzoeker {
    		margin-left: 17px;
    	}

    	.click_operator {
   			margin-left: 14px;
   		}

    	.click_inspecteur {
   			margin-left: 15px;
   		}

    	.click_monteur {
    		margin-left: 14px;
   		}

    	.click_service {
    		margin-left: 13px;
   		}
	}

	.welkom_text_wolken {
		height: 175px;

		.wolk {
			width: 220px;
			position: absolute;
			margin-top: -20px;
			opacity: 0;
		}

		.onderzoeker {
			background: url("../images/hoe_maken_wij_jouw_water/text_wolk_onderzoeker.svg");
			background-size: cover;
    		height: 161px;
    		left: -25px;
    		opacity: 1;
		}

		.operator {
			background: url("../images/hoe_maken_wij_jouw_water/text_wolk_operator.svg");
			background-size: cover;
    		height: 177px;
    		left: 123px;
		}

		.inspecteur {
			background: url("../images/hoe_maken_wij_jouw_water/text_wolk_inspecteur.svg");
			background-size: cover;
    		height: 140px;
    		left: 260px;
		}

		.monteur {
			background: url("../images/hoe_maken_wij_jouw_water/text_wolk_monteur.svg");
			background-size: cover;
    		height: 147px;
    		left: 404px;
		}

		.service {
			background: url("../images/hoe_maken_wij_jouw_water/text_wolk_service.svg");
			background-size: cover;
    		height: 127px;
    		left: 527px;
		}
	}

	.start_button {
		height: 55px;
		width: 136px;
		border-radius: 40px;
		background: $red;
		text-align: center;
		line-height: 80px;
		margin: auto;

		img {
			opacity: .5;
			transition: $transition;
		    -webkit-transition: $transition;
		    outline: none;
		    border: 0px;
		}

		&:hover img {
			opacity: 1;
		}

		a {
			display: block;
			height: 100%;
			border-radius: 40px;
		}
	}
}