* {
	outline: none;
}

a {
	color: white;
	text-decoration: none;
}

h1 {
	font-size: 18px;
	color: white;
	text-align: center;
	font-weight: 300;
}

h2 {
	font-size: 16px;
	color: $blue;
	text-align: center;
	font-weight: 300;
}