.is_touch {
	.duurzaam_text_wolken {
		.wolk {
			opacity: 0 !important;
		}
	}
}

.duurzaam_background {
	width: 100%;
	height: 100%;
	background: url("../images/duurzaam/duurzaam_wolken.svg") #a1dee9;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 900px;
	position: absolute;
}

.inner_container {
	.duurzaam_text {
		width: 378px;
    	height: 146.3px;
		background: url("../images/duurzaam/duurzaam_text.svg");
		background-size: cover;
	}

	.duurzaam_vijf {
		background-size: cover;
		position: absolute;

		.bordje {
			width: 175px;
			position: absolute;

			h1 {
				color: $blue;
				font-weight: 600;
				font-size: 18px;
				transition: $transition;
			    -webkit-transition: $transition;
			}

			&:hover h1 {
				background: $red;
				-webkit-box-shadow: inset 0px 4px 0px 0px rgba(239,101,101,1);
                -moz-box-shadow: inset 0px 4px 0px 0px rgba(239,101,101,1);
				box-shadow: inset 0px 4px 0px 0px rgba(239,101,101,1);
				color: white;
			}

			a {
				display: block;
    			height: 100%;
    			width: 100%;
    			top: 0px;
			    position: absolute;
			}
		}

		.bordje_bron {
			height: 215px;
    		background: url("../images/duurzaam/duurzaam_bron.svg");
    		background-size: contain;
    		background-repeat: no-repeat;
    		top: -369px;
    		left: 121px;

			h1 {
				line-height: 34px;
    			width: 166px;
    			height: 34px;
    			margin: 156px 3px;
			}
		}

		.bordje_duurzaam {
			height: 229px;
    		width: 201.9px;
			background: url("../images/duurzaam/duurzaam_duurzaam.svg");
    		background-size: contain;
    		background-repeat: no-repeat;
			top: -260px;
			left: 420px;

			h1 {
				line-height: 19px;
    			width: 146px;
    			height: 36px;
    			margin: 149px 0px;
    			padding: 10px 10px;
			}
		}

		.bordje_gebruik {
			height: 170.2px;
    		width: 178px;
			background: url("../images/duurzaam/duurzaam_gebruik.svg");
    		background-size: contain;
    		background-repeat: no-repeat;
			top: 20px;
			left: 260px;

			h1 {
				line-height: 19px;
	    		width: 158px;
	    		height: 40px;
	    		margin: 84px auto;
			    padding: 10px 10px;
			}
		}

		.bordje_kraan_fles {
			height: 180.98px;
			background: url("../images/duurzaam/duurzaam_kraan_fles.svg");
    		background-size: contain;
    		background-repeat: no-repeat;
			top: 20px;
			left: -50px;

			h1 {
				line-height: 19px;
    			width: 155px;
    			height: 38px;
    			margin: 97px auto;
    			padding: 10px 10px;
			}
		}

		.bordje_waterketen {
			height: 222.8px;
			background: url("../images/duurzaam/duurzaam_waterketen.svg");
    		background-size: contain;
    		background-repeat: no-repeat;
			top: -210px;
			left: -220px;

			h1 {
				line-height: 38px;
    			width: 175px;
    			height: 38px;
    			margin: 159px auto;
			}
		}
	}

	.duurzaam_text_wolken {
		.wolk {
			width: 220px;
			position: absolute;
			opacity: 0;
		}

		.bron {
			background: url("../images/duurzaam/duurzaam_wolk_bron.svg");
			background-size: cover;
    		height: 74px;
    		left: -65px;
    		top: -180px;
		}

		.duurzaam {
			background: url("../images/duurzaam/duurzaam_wolk_duurzaam.svg");
			background-size: cover;
    		height: 130px;
    		width: 185px;
    		left: 445px;
    		top: -200px;
		}

		.gebruik {
			background: url("../images/duurzaam/duurzaam_wolk_gebruik.svg");
			background-size: cover;
    		height: 64px;
    		left: 445px;
    		top: 260px;
		}

		.fles {
			background: url("../images/duurzaam/duurzaam_wolk_fles.svg");
			background-size: cover;
    		height: 81px;
    		left: -275px;
    		top: 205px;
		}

		.waterketen {
			background: url("../images/duurzaam/duurzaam_wolk_waterketen.svg");
			background-size: cover;
    		height: 198px;
    		width: 185px;
    		left: -255px;
    		top: -220px;
		}
	}
}